import React from "react";

import ProductDescCard from "../Reuse/ProductDescCard";
import ComponentTableImg from "../Reuse/ComponentTableImg";
import SpecTable from "../Reuse/SpecTable";
import GetProductDesc from "../GetProductDesc";

const Palummotortakoz = () => {
  const tableData = [
    {
      no: 1,
      desc: "GÖVDE",
      brand: "HASÇELİKLER",
      material: "ÇELİK SAC",
      count: "1",
    },
    {
      no: 2,
      desc: "TABAN KAUÇUK",
      brand: "HASÇELİKLER",
      material: "KAUÇUK",
      count: "1",
    },
    {
      no: 3,
      desc: "ÜST KAUÇUK",
      brand: "HASÇELİKLER",
      material: "KAUÇUK",
      count: "1",
    },
    {
      no: 4,
      desc: "SABİTLEME CİVATASI",
      brand: "HASÇELİKLER",
      material: "ÇELİK",
      count: "1",
    },
    {
      no: 5,
      desc: "KAPAK",
      brand: "HASÇELİKLER",
      material: "ÇELİK",
      count: "1",
    },
    {
      no: 6,
      desc: "SAPLAMA",
      brand: "HASÇELİKLER",
      material: "ÇELİK",
      count: "1",
    },
    {
      no: 7,
      desc: "GENİŞ PUL",
      brand: "HASÇELİKLER",
      material: "ÇELİK",
      count: "1",
    },
    {
      no: 8,
      desc: "YAYLI PUL",
      brand: "HASÇELİKLER",
      material: "ÇELİK",
      count: "1",
    },
    {
      no: 9,
      desc: "AYAR SOMUNU",
      brand: "HASÇELİKLER",
      material: "ÇELİK",
      count: "2",
    },
  ];



  const specData = [
    {
      fields: ["MT1-50","M16*1.5","104","145","70","50","20","26","12"],    
    },
    {
      fields: ["MT1-70","M16*1.5","104","145","70","50","20","26","12"],    
    },
    {
      fields: ["MT 2 M16","M16*1.5","130","165","95","55","25","29","14"],    
    },
    {
      fields: ["MT 2 M18","M18*1.5","130","165","95","55","25","29","14"],    
    },
    {
      fields: ["MT 2 M20","M20*1.5","130","165","95","55","25","29","14"],    
    },
    {
      fields: ["MT 3","M20*1.5","145","183","105","65","27","35","16"],    
    },
    {
      fields: ["MT 4","M24*3","145","183","105","65","27","35","16"],    
    },
  ];

  return (
    <div className="overflow-hidden h-auto w-screen absolute z-50 top-20  bg-gray-200 flex flex-wrap items-center justify-around">
      <div className="text-[4vmax] font-black tracking-wider text-[#212C42]   flex items-end w-screen p-8">
        ALUMİNYUM MOTOR TAKOZU
      </div>

      <div className="flex  items-center justify-center lg:max-w-[50vh]  ">
        <img
          src="/img/products/alum takoz.webp"
          alt="aluminyum motor takozu"
          className=""
        />
      </div>
      <div className="py-8 lg:max-w-[50vh] lg:pr-8 ">
        <ComponentTableImg tableData={tableData} />
      </div>

      <div className="px-4 my-8 lg:max-w-[50vh]  mix-blend-luminosity">
        <img
          src="/img/teknik/alum motor takozu teknik.webp"
          alt="aluminyum motor takozu"
          className=""
        />
      </div>
      <div className="desc py-8 lg:w-[50vw] lg:max-w-[50vw]  flex flex-row items-center justify-center lg:items-start lg:justify-start">
      <GetProductDesc productName="alummotortakozDesc"/>
      </div>

      <div className="desc py-8 w-full  flex flex-row items-center justify-center lg:items-start lg:justify-start">
        <SpecTable  cols={["TİP", "D", "A", "L", "H1", "H2","H3","D1","D2"]} specData={specData} />
      </div>
      <div className="flex  items-center justify-center lg:max-w-[50vh]  p-16">
        <img
          src="/img/products/motorlu takoz alum.webp"
          alt="sac motor takozu"
          className=""
        />
      </div>
    </div>
    
  );
};

export default Palummotortakoz;
