import React, { useEffect, useState } from 'react'
import ProductDescCard from './Reuse/ProductDescCard';

const GetProductDesc= ({productName}) => {
  const [selected, setSelected] = useState([]) 
  console.log(selected)




  const korukDesc = [
    {
      title: "Sızdırmazlık",
      desc: "Körüklü sızdırmazlık sistemi, karbon ve karşısında çalıştığı krom yüzey sayesinde şafttan bağımsız olarak sızdırmazlık sağlar.",
    },
    {
      title: "Koruyucu",
      desc: "Bu sayede şaft yüzeyinde oluşabilecek hasarların önüne geçilir.",
    },
    {
      title: "Sızdırmazlık",
      desc: "Motor line’ında olabilecek kaçıklıklarda hareket toleransından dolayı sızdırmazlık özelliğini korur.",
    },
  ];
  const skaplinDesc = [
    {
      title: "Sızdırmazlık",
      desc: "Montaj kolaylığından dolayı şaft boyunda kısaltma ve 3-4cm kadar ileri geri yapma imkanı verir.",
    },
    {
      title: "Koruyucu",
      desc: "Sıkmalı olduğu için şaftta konik işlemeye ve kama açmaya gerek kalmaz.",
    },
    {
      title: "Sızdırmazlık",
      desc: "Konik olmadığı ve şafta somunla bağlanmadığı için denizde söküp takmak daha kolaydır.",
    },
    {
        title: "Sızdırmazlık",
        desc: "Çelikten yapıldığı için uzun ömürlüdür.",
      },
  ];

  const ekaplinDesc = [
    {
      title: "Sızdırmazlık",
      desc: "Esnek Kaplin sistemde oluşabilecek vibrasyonları, vuruntuları sönümleyerek şanzıman ve şaft kaplininde oluşabilecek hasarların önüne geçer.",
    },
    {
      title: "Koruyucu",
      desc: "Pervaneye halat sarma, kayaya vurma gibi durumlarda sevk sistemine gelecek hasarları azaltır.",
    },
    {
      title: "Sızdırmazlık",
      desc: "Sistemde çalışma esnasında meydana gelen toleranstan sapma, ısıl genleşme vb. sebepler nedeniyle millerde meydana gelebilecek sorunların hissedilmesini engeller.",
    },
    {
        title: "Sızdırmazlık",
        desc: "Sistem normal çalıştığı sürece uzun yıllar bozulmadan, kırılmadan ve en az bakım gerektirecek şekilde ölçülendirilmiş ve uygun malzemelerden imal edilmişdir.",
      },
      {
        title: "Sızdırmazlık",
        desc: "Yüksek teknoloji ile üretilen hammaddesi sayesinde kauçuk kadar esnek olmasına rağmen kauçuktan çok daha fazla stabil özelliğe sahiptir.",
      },
      {
        title: "Sızdırmazlık",
        desc: "Deniz suyuna, yağa ve mazota karşı yüksek dayanıklılık gösterir. Bu özelliklerinden dolayı alternatiflerine göre çok daha uzun kullanım ömrüne sahiptir.",
      },
  ];

  const sacteknekovanDesc = [
    {
      title: "Sızdırmazlık",
      desc: "Sac teknelerde, kovan teknenin gövdesine kaynakla bağlanır.",
    },
    {
      title: "Koruyucu",
      desc: " Kaynak yapılırken boruda yamulma oluşmaması için et kalınlığı 20mm ve üzeri borular tercih edilir.",
    },
    {
      title: "Sızdırmazlık",
      desc: "Kovanın içindeki şaft yataklarının aşındığı zaman değiştirebilmesi için bronz yataklar kovan borusunun alnından civatalarla bağlanır.",
    },
  ];

  const sacmotortakozDesc = [
    {
      title: "Sızdırmazlık",
      desc: "Motor titreşimini sönümleyen özel kauçuk takozun gövdesinde kapakla korunduğu için uzun ömürlüdür.",
    },
    {
      title: "Koruyucu",
      desc: "Değişik çaplarda ve yukarı aşağı oynatılabilen saplamaları sayesinde kolay montaj sağlar.",
    },
    {
      title: "Sızdırmazlık",
      desc: "Geniş pullu taban somunu ve sertleştirilmiş çelik saplaması sayesinde dayanıklı ve uzun ömürlüdür.",
    },
    {
      title: "Koruyucu",
      desc: " Tabandaki sloplar sayesinde montajı kolaydır.",
    },
  ];

  const saftyatakDesc = [
    {
      title: "Sızdırmazlık",
      desc: "Şaft yatağının dışı bronz, iç tarafı kauçuktan oluşmaktadır.",
    },
    {
      title: "Koruyucu",
      desc: "Pervane şaftı, yatak içerisinde dönerken şaft yatağının içinden deniz suyu geçmektedir, bu şaftın sürtünmeden dolayı ısınmasını ve yatakta oluşabilecek hasarların önüne geçer.",
    },
    {
      title: "Sızdırmazlık",
      desc: "Şaft yatağı uzun ömürlü olması ve şafta zarar vermemesinden dolayı fiber, delrin gibi alternatiflerine göre daha avantajlıdır.",
    },
    ]  

    const saftkeceDesc = [
      {
        title: "Sızdırmazlık",
        desc: "Gövde içerisindeki keçeler sayesinde şaftta sızdırmazlığı sağlar.",
      },
      {
        title: "Koruyucu",
        desc: "Üretimde kullanılan hammadde ve kalın gövde yapısı sayesinde deniz suyuna mazota ve yağa karşı çok yüksek mukavemet gösterir.",
      },
      {
        title: "Sızdırmazlık",
        desc: "Keçe dudaklarının yumuşak yapısı sayesinde şaftta oluşabilecek sürtme izleri çok aza indirilmiştir.",
      },
      {
          title: "Sızdırmazlık",
          desc: " Esnek gövde özelliği ile yüksek devirle çalışan şaftlardada üstün sızdırmazlık özelliğine sahiptir.",
        },
        {
          title: "Sızdırmazlık",
          desc: "Dikkat Edilmesi Gereken Hususlar",
        },
        {
          title: "Sızdırmazlık",
          desc: "Keçeyi mile takmadan önce milin ucunda çapak olmadığından emin olun.",
        },
        {
          title: "Sızdırmazlık",
          desc: "Montajı kolaylaştırmak için keçenin içerisine, keçenin yanında size verdiğimiz sıvı gres’i az miktarda sürün.",
        },
        {
          title: "Sızdırmazlık",
          desc: "Keçeyi takmadan önce milin yüzeyini temizleyin.",
        },
        {
          title: "Sızdırmazlık",
          desc: "Keçeyi mil’e taktıktan sonra hafifçe ileri-geri yapın ve keçenin içindeki dudakların yüzeye oturduğundan emin olun.",
        },
        {
          title: "Sızdırmazlık",
          desc: "Keçe’ye aşırı miktarda gres basmayın, keçenin içindeki dudaklar birbirine yapışabilir.",
        },
        {
          title: "Sızdırmazlık",
          desc: "Keçeyi pervane miline yerleştirdikten sonra kelepçeyi sıktığınızdan emin olun.",
        },
    ];

    const yanmarmotortakozDesc = [
      {
        title: "Sızdırmazlık",
        desc: "Motor titreşimini sönümleyen özel kauçuk takozun gövdesinde kapakla korunduğu için uzun ömürlüdür.",
      },
      {
        title: "Koruyucu",
        desc: "Değişik çaplarda ve yukarı aşağı oynatılabilen saplamaları sayesinde kolay montaj sağlar.",
      },
      {
        title: "Sızdırmazlık",
        desc: "Geniş pullu taban somunu ve sertleştirilmiş çelik saplaması sayesinde dayanıklı ve uzun ömürlüdür.",
      },
    ];

    const alummotortakozDesc = [
      {
        title: "Sızdırmazlık",
        desc: "Özel alaşımlı alüminyum gövdesi korozyona daha dayanıklıdır, diğer takoz gövdeleri gibi paslanma yapmaz.",
      },
      {
        title: "Koruyucu",
        desc: "Titreşim sönümleyen kauçuk gövde içerisinde gizlendiği için diğer takozlara göre çok daha uzun ömürlüdür.",
      },
      {
        title: "Sızdırmazlık",
        desc: "Saplamaları gövde içerisinde kauçuk üzerinde sabitlendiği için titreşim sönümlemesi ve dayanımı çok yüksek değerlerdedir.",
      },
    ];


    const saftDesc = [
      {
        title: "Sızdırmazlık",
        desc: "şaft açıklaması",
      },

    ];
  
useEffect(()=>{
 if (productName==="korukDesc"){setSelected(korukDesc)}
 if (productName==="sacteknekovan"){setSelected(sacteknekovanDesc)}
 if (productName==="saftDesc"){setSelected(saftDesc)}
 if (productName==="skaplinDesc"){setSelected(skaplinDesc)}
 if (productName==="ekaplinDesc"){setSelected(ekaplinDesc)}
 if (productName==="sacteknekovanDesc"){setSelected(sacteknekovanDesc)}
 if (productName==="sacmotortakozDesc"){setSelected(sacmotortakozDesc)}
 if (productName==="yanmarmotortakozDesc"){setSelected(yanmarmotortakozDesc)}
 if (productName==="alummotortakozDesc"){setSelected(alummotortakozDesc)}

 if (productName==="saftyatakDesc"){setSelected(saftyatakDesc)}
 if (productName==="saftkeceDesc"){setSelected(saftkeceDesc)}

 
},[])
   // console.log(selected)

  return (
<>
<div className="px-6  flex flex-col ">
      <h2 className="text-3xl font-bold  text-[#212C42]">Özellikler</h2>

      {selected.map((item, i) => (
        <div
          className=" text-left border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700"
          key={i}
        >
          {/*  <h5 className="mb-2 text-lg font-bold text-gray-900">{item.title}</h5>   */}
          <li className=" list-disc mb-5 text-1/4 text-gray-400 font-light sm:text-m dark:text-gray-400 ">
          
            {item.desc}
          </li>
          <div className="items-center justify-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4 rtl:space-x-reverse"></div>
        </div>
      ))}
    </div>
</>
  )  
}



  //export const DescData={koruk}
  export default GetProductDesc


