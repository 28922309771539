import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import "./Home.css";
import Header from "./Components/Header.jsx";
import { BrowserRouter, Route, Routes} from "react-router-dom";
import Psaftyatak from "./Components/Pages/Psaftyatak.jsx";
import Pshaft from "./Components/Pages/Pshaft.jsx";
import Pkovan from "./Components/Pages/Pkovan.jsx";
import Pahsapkovsis from "./Components/Pages/Pahsapkovsis.jsx";
import Pcelikcubuk from "./Components/Pages/Pcelikcubuk.jsx";
import Ppcelikcekme from "./Components/Pages/Ppcelikcekme.jsx";
import Psacmotortakoz from "./Components/Pages/Psacmotortakoz.jsx";
import Palummotortakoz from "./Components/Pages/Palummotortakoz.jsx";
import Pyanmarmotortakoz from "./Components/Pages/Pyanmarmotortakoz.jsx";
import Pkoruk from "./Components/Pages/Pkoruk.jsx";
import Pskaplin from "./Components/Pages/Pskaplin.jsx";
import Pekaplin from "./Components/Pages/Pekaplin.jsx";
import Home from "./Components/Home.jsx";
import Pcelikcekmeboru from "./Components/Pages/Pcelikcekmeboru.jsx";
import Ppervane from "./Components/Pages/Ppervane.jsx";
import Footerbar from "./Components/Footer.jsx";
import Psacsevk from "./Components/Pages/Psacsevk.jsx";
import Pahsapsevk from "./Components/Pages/Pahsapsevk.jsx";
import Contact from "./Components/Contact.jsx";
import Headert from "./Components/Headert.jsx";
import { Footer } from "flowbite-react";
import About from "./Components/Pages/About.jsx";
import ScrollToTop from "./Components/ScrollToTop.jsx";

gsap.registerPlugin(ScrollTrigger);

function App() {
  return (
    <div className="App ">
     
      <BrowserRouter>
        <Header/>
        <Routes>
        
          <Route exact path="/" element={<Home />} />
          <Route path="/Sac-Tekne-Sevk-Sistemi" element={<Psacsevk/>} />
          <Route path="/Ahsap-Tekne-Sevk-Sistemi" element={<Pahsapsevk />} />
          <Route path="/Pervane" element={<Ppervane />} />
          <Route path="/Saft-Kecesi" element={<Pshaft />} />
          <Route path="/Saft-Korugu" element={<Pkoruk />} />
          <Route path="/Saft-Yatağı" element={<Psaftyatak />} />
          <Route path="/Esnek-Kaplin" element={<Pekaplin />} />
          <Route path="/Sikmali-Kaplin" element={<Pskaplin />} />
          <Route path="/Sac-Tekne-Kovan-Sistemi" element={<Pkovan />} />
          <Route path="/Ahsap-Tekne-Kovan-Sistemi" element={<Pahsapkovsis />} />
          <Route path="/Paslanmaz-Celik-Cubuk" element={<Pcelikcubuk />} />
          <Route path="/Paslanmaz-Celik-Cekme-Boru" element={<Ppcelikcekme />} />
          <Route path="/Celik-Cekme-Boru" element={<Pcelikcekmeboru />} />
          <Route path="/Sac-Motor-Takozu" element={<Psacmotortakoz />} />
          <Route path="/Aluminyum-Motor-Takozu" element={<Palummotortakoz />} />
          <Route path="/Yanmar-Motor-Takozu" element={<Pyanmarmotortakoz />} />
          <Route path="/Hakkimizda" element={<About/>}/>
          <Route path="/Contact" element={<Contact/>}/>

        </Routes>
        <Footer />
        <ScrollToTop />
      </BrowserRouter>
    </div>
  );
}

export default App;
