import React from "react";

import ProductDescCard from "../Reuse/ProductDescCard";
import ComponentTableImg from "../Reuse/ComponentTableImg";
import SpecTable from "../Reuse/SpecTable";

const Pcelikcubuk = () => {

  const specData = [
    {
      fields: ["13", "1,060"],
    },
    {
      fields: ["14", "1,230"],
    },
    {
      fields: ["15", "1,410"],
    },
    {
      fields: ["16", "1,610"],
    },
    {
      fields: ["17", "1,820"],
    },
    {
      fields: ["18", "2,040"],
    },
    {
      fields: ["19", "2,270"],
    },
    {
      fields: ["20", "2,510"],
    },
    {
      fields: ["21", "2,770"],
    },
    {
      fields: ["22", "3,040"],
    },
    {
      fields: ["23", "3,320"],
    },
    {
      fields: ["24", "3,620"],
    },
    {
      fields: ["25", "3,930"],
    },
    {
      fields: ["26", "4,250"],
    },
    {
      fields: ["28", "4.920"],
    },
    {
      fields: ["30", "5.650"],
    },
    {
      fields: ["32", "6.430"],
    },
    {
      fields: ["35", "7.700"],
    },
    {
      fields: ["38", "9.070"],
    },
    {
      fields: ["40", "10.100"],
    },
    {
      fields: ["45", "12.700"],
    },
    {
      fields: ["50", "15.700"],
    },
    {
      fields: ["55", "19.000"],
    },
    {
      fields: ["60", " 22.600"],
    },
    {
      fields: ["65", "26.500"],
    },
    {
      fields: ["70", "30.800"],
    },
    {
      fields: ["75", "35.300"],
    },
    {
      fields: ["80", "40.200"],
    },
    {
      fields: ["85", "45.400"],
    },
    {
      fields: ["90", " 50.900"],
    },

    {
      fields: ["95", "56.700"],
    },
    {
      fields: ["100", "62.800"],
    },
    {
      fields: ["110", "76.000"],
    },
    {
      fields: ["120", "90.400"],
    },
    {
      fields: ["130", "106.100"],
    },
    {
      fields: ["140", "123.100"],
    },
    {
      fields: ["150", "141.300"],
    },
    {
      fields: ["160", "160,800"],
    },

    {
      fields: ["170", "181,500"],
    },
    {
      fields: ["180", " 203,500"],
    },
    {
      fields: ["190", "226,700"],
    },
    {
      fields: ["200", "251,200"],
    },
    {
      fields: ["210", "277"],
    },
    {
      fields: ["220", "304"],
    },
  ];

  const specData2 = [
    {
      fields: ["16", "16"],
    },
    {
      fields: ["20", "22"],
    },
    {
      fields: ["25", "25"],
    },
    {
      fields: ["28", "28"],
    },
    {
      fields: ["30", "30"],
    },
    {
      fields: ["32", "32"],
    },

    {
      fields: ["35", "35"],
    },
    {
      fields: ["38", "38"],
    },
    {
      fields: ["40", "40"],
    },
    {
      fields: ["45", "44,45"],
    },
    {
      fields: ["50", "45"],
    },
    {
      fields: ["55", "50"],
    },
    {
      fields: ["60", "50,8"],
    },
    {
      fields: ["65", "55"],
    },
    {
      fields: ["70", "57,15"],
    },
    {
      fields: ["75", "60"],
    },
    {
      fields: ["80", "63,5"],
    },
    {
      fields: ["85", "65"],
    },
    {
      fields: ["90", "70"],
    },
    {
      fields: ["100", "75"],
    },
    {
      fields: ["110", "80"],
    },
    {
      fields: ["120", "85"],
    },
    {
      fields: ["130", "90"],
    },
    {
      fields: ["140", "100"],
    },
    {
      fields: ["150", "110"],
    },
    {
      fields: ["160", "120"],
    },
    {
      fields: ["170", "130"],
    },
    {
      fields: ["180", "140"],
    },
    {
      fields: ["190", "150"],
    },

    {
      fields: ["200", "160"],
    },
    {
      fields: ["210", "170"],
    },
    {
      fields: ["220", "180"],
    },
    {
      fields: ["", "190"],
    },
    {
      fields: ["", "200"],
    },
    {
      fields: ["", "210"],
    },
    {
      fields: ["", "220"],
    },
  ];

  const specData3 = [
    {
      fields: ["25", "55","25","16*1,5","6*3/8*4","45","25","16*1,5"],
    },
    {
      fields: ["30", "65","30","18*1,5","8*4","45","25","18*1,5"],
    },
    {
      fields: ["35", "75","35","20*1,5","10*5","55","30","20*1,5"],
    },
    {
      fields: ["40", "85","40","24*1,5","10*5","55","30","24*1,5"],
    },
    {
      fields: ["45", "95","45","27*1,5","12*6","70","35","27*1,5"],
    },
    {
      fields: ["50", "115","50","36*3","12*6","70","35","36*3"],
    },
    {
      fields: ["55", "125","55","40*3","14*7","85","35","40*3"],
    },
    {
      fields: ["60", "135","60","42*3","16*7","85","35","42*3"],
    },
    {
      fields: ["70", "155","70","45*3","18*9","115","35","45*3"],
    },
    {
      fields: ["80", "175","80","56*3","18*9","125","38","56*3"],
    },
    {
      fields: ["90", "195","90","64*3","20*10","125","50","64*3"],
    },
    {
      fields: ["100", "215","100","72*3","22*11","145","60","72*3"],
    },
  ]



  const specData4 = [
    {
      fields: ["25", "38*3,2"],
    },
    {
      fields: ["30", "42,4*3,2"],
    },
    {
      fields: ["35", "48,3*3,7"],
    },
    {
      fields: ["35", "50*5"],
    },
    {
      fields: ["35", "55*5"],
    },
    {
      fields: ["40", "60,3*3,91"],
    },
    {
      fields: ["40", "60,3*5,54"],
    },
    {
      fields: ["45", "66*5"],
    },
    {
      fields: ["45", "70*6"],
    },
    {
      fields: ["50", "73*5"],
    },
    {
      fields: ["55", "76*5"],
    },
    {
      fields: ["60", "80*6"],
    },
    {
      fields: ["70", "88,9*5*49"],
    },
    {
      fields: ["80", "101,3*5,74"],
    },
    {
      fields: ["90", "114,3*6"],
    },
  ]
  const specData5 = [
    {
      fields: ["40", "121/20"],
    },
    {
      fields: ["45", "121/20-133/25"],
    },
    {
      fields: ["50", "133/20-140/25"],
    },
    {
      fields: ["55", "140/20-152/25"],
    },
    {
      fields: ["60", "152/20-159/25"],
    },
    {
      fields: ["70", "159/20-168/25"],
    },
    {
      fields: ["80", "180/25-194/30"],
    },
    {
      fields: ["90", "194/25-203/30"],
    },
    {
      fields: ["100", "203/25-219/30"],
    },
    {
      fields: ["50", "73*5"],
    },
    {
      fields: ["55", "76*5"],
    },
    {
      fields: ["60", "80*6"],
    },
    {
      fields: ["70", "88,9*5*49"],
    },
    {
      fields: ["80", "101,3*5,74"],
    },
    {
      fields: ["90", "114,3*6"],
    },
  ]

  return (
    <div className="overflow-hidden h-auto w-screen  z-50  flex justify-center  bg-gray-200 ">
      <div className="lg:w-[60vw] flex flex-wrap pt-20 items-start justify-around">
        <div className="text-[4vmax] px-4 font-black tracking-wider text-[#212C42]   flex items-end w-screen  pt-8">
          PASLANMAZ ÇELİK ÇUBUK
        </div>

        <div className="flex  items-center justify-center lg:max-w-[50vh] -mt-24 mix-blend-multiply">
          <img
            src="/img/products/celik cubuk.webp"
            alt="ahşap tekne kovan sistemi"
            className=""
          />
        </div>

        <div className="flex flex-row gap-12">
          <div className="desc  flex flex-row items-center justify-center lg:items-start lg:justify-start lg:mt-12">
            <SpecTable cols={["ÇAP (mm)", "KG/M"]} specData={specData} />
          </div>
          <div className="desc  flex flex-row items-start justify-center lg:items-start lg:justify-start lg:mt-12">
            <SpecTable
              cols={["304 Ø", "1,4462 (DUBLEKS) Ø"]}
              specData={specData2}
            />
          </div>
        </div>
        <div className="py-8 w-[100vw]  mix-blend-luminosity">
          <img
            src="/img/teknik/saft teknik.webp"
            alt="Şaft Yatağı"
            className=""
          />
        </div>
        <div className="desc py-8 flex flex-row items-center justify-center lg:items-start lg:justify-start lg:mt-12">
            <SpecTable
              cols={["A", "C","B","D","CH","F","E","G"]}
              specData={specData3}
            />
          </div>
          <div className="desc py-8 flex flex-row items-center justify-center lg:items-start lg:justify-start lg:mt-12">
            <SpecTable
              cols={["ŞAFT ÇAPI", "BORU ÇAP/KALINLIĞI"]}
              specData={specData4}
            />
          </div>
          <div className="desc py-8 flex flex-row items-center justify-center lg:items-start lg:justify-start lg:mt-12">
            <SpecTable
              cols={["ŞAFT ÇAPI", "BORU ÇAP/KALINLIĞI"]}
              specData={specData5}
            />
          </div>
      </div>
    </div>
  );
};

export default Pcelikcubuk;
