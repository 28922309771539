import { Card } from "flowbite-react";
import React from "react";

export const CardsPervane = ({ img, title, desc }) => {
  console.log(title);
  return (
    <div>
      <Card className="anim-fadein mt-6 w-screen lg:w-[30vw] h-[40vh] md:h-[20vh]">
        <div className="flex flex-col items-center pb-10">
          <img
            src={img}
            alt="Bonnie image"
            height="96"
            width="96"
            className="mb-3 rounded-full shadow-lg"
          />
          <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
            {title}
          </h5>
          <span className="text-sm text-gray-500 dark:text-gray-400">
            {desc}
          </span>
        </div>
      </Card>
    </div>
  );
};
