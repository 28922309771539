import React from "react";

import ProductDescCard from "../Reuse/ProductDescCard";
import ComponentTableImg from "../Reuse/ComponentTableImg";
import SpecTable from "../Reuse/SpecTable";
import GetProductDesc from "../GetProductDesc";

const Pskaplin = () => {


  const specData = [

      {
        fields: ["102","78","50","80","80","22-40","10,2","10","4"],    
      },
      {
        fields: ["102","80","60","80","80","22-40","10,2","10","4"],    
      },
      {
        fields: ["102","82,55","63,5","80","80","22-40","10,2","10","4"],    
      },
      {
        fields: ["116","95","65","100","100","25-40","10,2","15","6"],    
      },
      {
        fields: ["127","100","65","100","100","25-50","10,2","15","4"],    
      },
      {
        fields: ["127","107,95","63,5","100","100","25-50","11","15","4"],    
      },
      {
        fields: ["127","107,95","63,5","100","100","25-50","11","15","4"],    
      },
      {
        fields: ["127","103","75","100","100","25-50","10,2","15","6"],    
      },
      {
        fields: ["150","120,65","76,2","135","141","35-70","12,7","25","6"],    
      },
      {
        fields: ["150","120,65","76,2","135","141","35-70","14","25","6"],    
      },
    ]  

  return (
    <div className="overflow-hidden h-auto w-screen absolute z-50 top-20  bg-gray-200 flex flex-wrap items-center justify-around">
      <div className="text-[4vmax] font-black tracking-wider text-[#212C42]   flex items-end w-screen p-8">
        Sıkmalı Kaplin
      </div>

      <div className="flex  items-center justify-center lg:max-w-[50vh]  p-16">
        <img
          src="/img/products/Sıkmalı kaplin.webp"
          alt="Sıkmalı Kaplin"
          className=""
        />
      </div>

      <div className="px-4 my-16 lg:max-w-[50vh]  mix-blend-luminosity">
        <img
          src="/img/teknik/sıkmalı kaplin teknik.webp"
          alt="Sıkmalı Kaplin"
          className=""
        />
      </div>
      <div className="desc lg:max-w-[50vw]  flex flex-row items-center justify-center lg:items-start lg:justify-start">
      <GetProductDesc productName="skaplinDesc"/>
      </div>

      <div className="desc w-full  flex flex-row items-center justify-center lg:items-start lg:justify-start">
        <SpecTable  cols={["A", "B", " C", " D", " H", "S","Q","L","R"]} specData={specData} />
      </div>
    </div>
    
  );
};

export default Pskaplin;
