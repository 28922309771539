import React from "react";
import { CardsPervane } from "./CardsPervane";

export default function Pervane() {
  return (
    <section className="pervane-section swipe-section panel flex flex-col justify-start items-center h-screen w-screen absolute md:top-[40vh] top-[30vh] bg-gray-200 z-20">
      <section className=" py-6  text-[#212C42]">
        <div className="container flex flex-col items-center justify-center pb-4  space-y-4 ">
          <h1 className="text-4xl font-bold leadi text-center sm:text-5xl">
            PERVANE İMALAT SÜRECİMİZ
          </h1>
          <p className="max-w-2xl text-center dark:text-gray-400">
            Üretim sürecinin her adınımı üstlendiğimizi özetleyen bir paragraf
            metin...
          </p>
          <div className="grid grid-flow-col justify-start items-start pervane-slider h-8 w-full p-20 ">
            <section className="pervane-slider swipe-section panel flex flex-row gap-10 justify-end items-start h-[50vh] w-full absolute  bg-gray-200 z-20">
             <CardsPervane img="./img/pervane/pervane tasarim.webp" title="1 TASARIM" desc="Teknenin teknik ölçüleri ve motor özellikleri bilgisayar programına girilerek uygun pervanenin ölçüleri çıkarılır, bu ölçüler kullanılarak pervanenin 3 boyutlu çizimi yapılır."/>
             <CardsPervane img="./img/pervane/pervane modelleme.webp" title="2 MODELLEME" desc="Router ve Cnc dik işlem makinaları kullanılarak pervane modeli ahşaptan veya plastikten yapılır."/>
             <CardsPervane img="./img/pervane/pervane.webp" title="3 KALIPLAMA" desc="Pervane modeliniz bronz döküm atölyemizde kalıplanır."/>
             <CardsPervane img="./img/pervane/pervane.webp" title="4 DÖKÜM" desc="Modeli hazırlanan pervane döküm atölyesinde kalıplanıp bronz olarak dökülür cu1,cu2,cu3 standartlarında dökmek mümkündür."/>
             <CardsPervane img="./img/pervane/pervane delik.webp" title="5 DELME" desc="Pervane döküldükten sonra torna atölyesinde uygun şaft ölçüsüne göre pervanenin göbeği delinir."/>
             <CardsPervane img="./img/pervane/pervane kama.webp" title="6 KAMA AÇMA" desc="Pervane göbek işlemi tamamlandıktan sonra Planya makinasında kaması açılır."/>
             <CardsPervane img="./img/pervane/pervane.webp" title="7 TAŞLAMA" desc="Pervane kaması açıldıktan sonra taşlama bölümüne çıkar. Yüzeyi taşlanır ve balansı alınır."/>

            </section>
          </div>
        </div>
      </section>
    </section>
  );
}
