import React from "react";

import ProductDescCard from "../Reuse/ProductDescCard";
import ComponentTableImg from "../Reuse/ComponentTableImg";
import SpecTable from "../Reuse/SpecTable";
import GetProductDesc from "../GetProductDesc";

const Psaftyatak = () => {
  const tableData = [
    {
      no: 1,
      desc: "GÖVDE",
      brand: "HASÇELİKLER",
      material: "KAUÇUK",
      count: "1",
    },
    {
      no: 2,
      desc: "KELEPÇE",
      brand: "HASÇELİKLER",
      material: "PASLANMAZ ÇELİK",
      count: "1",
    },
    {
      no: 3,
      desc: "HORTUM AĞZI",
      brand: "HASÇELİKLER",
      material: "PİRİNÇ",
      count: "1",
    },

  ];



    const specData = [

      {
        fields: ["22","40","45","115"],    
      },
      {
        fields: ["25","40-42-48-50","45","115"],    
      },
      {
        fields: ["28","45","45","115"],    
      },
      {
        fields: ["30"," 45-48-50-55","45","115"],    
      },
      {
        fields: ["32","48-55","45","115"],    
      },
      {
        fields: ["35","48-55-60","45","115"],    
      },
      {
        fields: ["38","60","45","120"],    
      },
      {
        fields: ["40","60-65-70","45","125"],    
      },
      {
        fields: ["45","60-65-70-75-90","45","125"],    
      },
      {
        fields: ["50"," 75-80-90","45","128"],    
      },
      {
        fields: ["55","80-90","45","128"],    
      },
      {
        fields: ["60","90-100","45","128"],    
      },
      {
        fields: ["63,5","90-100","50","170"],    
      },
      {
        fields: ["65","100","50","170"],    
      },
      {
        fields: ["70","100","50","170"],    
      },
      {
        fields: ["75","110","50","170"],    
      },
      {
        fields: ["80","110","50","170"],    
      },
      {
        fields: ["85","120","50","170"],    
      },
      {
        fields: ["90","120","50","170"],    
      },
      {
        fields: ["100","145","65","220"],    
      },
      {
        fields: ["110","155","65","220"],    
      },
      {
        fields: ["120","175","65","235"],    
      },
      {
        fields: ["130","185","65","250"],    
      },
      {
        fields: ["140","195","65","250"],    
      },   
    ]  


  return (
    <div className="overflow-hidden h-auto w-screen absolute z-50 top-20  bg-gray-200 flex flex-wrap items-center justify-around">
      <div className="text-[4vmax] font-black tracking-wider text-[#212C42]   flex items-end w-screen p-8">
        ŞAFT YATAĞI
      </div>

      <div className="flex  items-center justify-center lg:max-w-[50vh]  p-16">
        <img
          src="/img/products/Saft yatagı.webp"
          alt="Şaft Yatağı"
          className=""
        />
      </div>
      <div className="lg:max-w-[50vh] lg:pr-8 ">
        <ComponentTableImg tableData={tableData} />
      </div>

      <div className="px-8 my-16 lg:max-w-[50vh]  mix-blend-luminosity">
        <img
          src="/img/teknik/saft yatagı teknik.webp"
          alt="Şaft Yatağı"
          className=""
        />
      </div>
      <div className="desc lg:w-[50vw] lg:max-w-[50vw]  flex flex-row items-center justify-center lg:items-start lg:justify-start">
      <GetProductDesc productName="saftyatakDesc"/>
      </div>

      <div className="desc w-full  flex flex-row items-center justify-center lg:items-start lg:justify-start py-4">
        <SpecTable  cols={["Q(mm)", "P(mm)", "O(mm)", "L(mm)"]} specData={specData} />
      </div>
    </div>
    
  );
};

export default Psaftyatak;
