import React from 'react'

const About = () => {
  return (

    <div>Hakkimizda</div>

  )
}

export default About