import React from "react";

import ProductDescCard from "../Reuse/ProductDescCard";
import ComponentTableImg from "../Reuse/ComponentTableImg";
import SpecTable from "../Reuse/SpecTable";
import GetProductDesc from "../GetProductDesc";

const Pkoruk = () => {
  const tableData = [
    {
      no: 1,
      desc: "GÖVDE",
      brand: "HASÇELİKLER",
      material: "KAUÇUK",
      count: "1",
    },
    {
      no: 2,
      desc: "KÖMÜR",
      brand: "HASÇELİKLER",
      material: "KARBON",
      count: "1",
    },
    {
      no: 3,
      desc: "AYNA",
      brand: "HASÇELİKLER",
      material: "PASLANMAZ ÇELİK",
      count: "1",
    },
    {
      no: 4,
      desc: "AYAR KELEPÇESİ",
      brand: "HASÇELİKLER",
      material: "BRONZ",
      count: "1",
    },
    {
      no: 5,
      desc: "HORTUM AĞZI",
      brand: "HASÇELİKLER",
      material: "BRONZ",
      count: "1",
    },
    {
      no: 6,
      desc: "KELEPÇE",
      brand: "HASÇELİKLER",
      material: "PASLANMAZ ÇELİK",
      count: "4",
    },
  ];


  const specData = [
    {
      fields: ["25","48","50","140"," 1″","1.1/2″-1.3/4″-2″"],    
    },
    {
      fields: ["30","55","50","140","1,1/8","1.1/2″-1.3/4″-2″"],    
    },
    {
      fields: ["35","60","50","140","1,1/4″","1.1/2″-1.3/4″-2″"],    
    },
    {
      fields: ["40","65","50","175","1,3/8″","2″-2.1/4″"],    
    },
    {
      fields: ["45","70","50","175","1,1/2″","2″-2.1/4″-2.1/2″-2.3/4″"],    
    },
    {
      fields: ["50","80","50","175","1,3/4″","2.1/4″-2.1/2″-2.3/4″-3″"],    
    },
    {
      fields: ["55","90","50","175","2″","2.1/2″-2.3/4″-3″-3.1/2″"],    
    },
    {
      fields: ["60","90","50","190","2,1/4″","2.3/4″-3″-3.1/2″-4″"],    
    },
    {
      fields: ["65","100","50","200","2,1/2″","3″-3.1/2″-4″-4.1/8″"],    
    },
    {
      fields: ["70","100","50","200","3″","4″-4.1/4″-4.1/2″-4.3/4″"],    
    },
    {
      fields: ["75","105","50","210","3,1/4″","4.1/4″-4.1/2″-4.3/4″-5″"],    
    },
    {
      fields: ["80","110","50","210","3,1/2″","4.1/2″-4.3/4″-5″-5.1/2″"],    
    },
    {
      fields: ["85","115","50","210","3,3/4″","4.3/4″-5″-5.1/2″"],    
    },
    {
      fields: ["90","120","50","210","4″","5″-5.1/2″-5.3/4″-6″"],    
    },
    {
      fields: ["95","125","50","210"," 4,1/2″","5.1/2″-5.3/4″-6″-6.1/2″"],    
    },
    {
      fields: ["100","145","50","220","",""],    
    },



   
  ];

  return (
    <div className="overflow-hidden h-auto w-screen absolute z-50 top-20  bg-gray-200 flex flex-wrap items-center justify-around">
      <div className="text-[4vmax] font-black tracking-wider text-[#212C42]   flex items-end w-screen p-8">
        ŞAFT KÖRÜĞÜ
      </div>

      <div className="flex  items-center justify-center lg:max-w-[50vh]  p-16">
        <img
          src="/img/products/saft korugu.webp"
          alt="Şaft Körüğü"
          className=""
        />
      </div>
      <div className="lg:max-w-[50vh] lg:pr-8 ">
        <ComponentTableImg tableData={tableData} />
      </div>

      <div className="px-4 my-16 lg:max-w-[50vh]  mix-blend-luminosity">
        <img
          src="/img/teknik/saft korugu teknik.webp"
          alt="Şaft Körüğü"
          className=""
        />
      </div>
      <div className="desc lg:w-[50vw] lg:max-w-[50vw]  flex flex-row items-center justify-center lg:items-start lg:justify-start">
      <GetProductDesc productName="korukDesc"/>
      </div>

      <div className="desc w-full  flex flex-row items-center justify-center lg:items-start lg:justify-start py-4">
        <SpecTable  cols={["Q(mm)", "P(mm)", "O(mm)", "L(mm)", "Q(inch)", "P(inch)"]} specData={specData} />
      </div>
    </div>
    
  );
};

export default Pkoruk;
