import React from 'react';
import "./loading.css";
import { ReactSVG } from "react-svg";

export default function Loading() {
  return (
    <div className='loading-section' >
    <div className='propeller-container'><ReactSVG src={"./loading_propeller.svg"} className="loading-propeller" alt='svg'/></div>
    </div>
  )
}

