import React from "react";

const ProductDescCard = ({ descData }) => {


  return (
    <div className="px-6  flex flex-col ">
      <h2 className="text-3xl font-bold py-2 text-[#212C42]">Özellikler</h2>

      {descData.map((item, i) => (
        <div
          className=" text-left border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700"
          key={i}
        >
          {/*  <h5 className="mb-2 text-lg font-bold text-gray-900">{item.title}</h5>   */}
          <li className=" list-disc mb-5 text-base text-gray-400 sm:text-m dark:text-gray-400">
            {item.desc}
          </li>
          <div className="items-center justify-center space-y-4 sm:flex sm:space-y-0 sm:space-x-4 rtl:space-x-reverse"></div>
        </div>
      ))}
    </div>
  );
};

export default ProductDescCard;
