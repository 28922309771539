import React from "react";
import { GoogleMap, useLoadScript, Marker } from "@react-google-maps/api";

const libraries = ["places"];
const mapContainerStyle = {
  //width:'50vw',
  // height: '40vh',
};
const center = {
  lat: 38.43082, // default latitude
  lng: 27.17479, // default longitude
};

const Googlemapcomp = () => {
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: "AIzaSyB-kCNmmh3xdBGh0eW1k_0hBC8KWU8eRWg",
    libraries,
  });

  if (loadError) {
    return <div>Error loading maps</div>;
  }

  if (!isLoaded) {
    return <div>Loading maps</div>;
  }

  return (
    <div>
      <GoogleMap
        mapContainerClassName="m-12 w-[80vw] h-[40vh]"
        mapContainerStyle={mapContainerStyle}
        zoom={20}
        center={center}
      >
        <Marker position={center} />
      </GoogleMap>
    </div>
  );
};

export default Googlemapcomp;
