import React from "react";
import ComponentTableImg from "./Reuse/ComponentTableImg";

export const Hero = () => {
  const tableData = [
    {
      img: "./img/products/Pervane.webp",
      desc:"PERVANE",
      brand: "HASÇELİKLER",
      material: "PİRİNÇ",
      count: "1",
    },
    {
      img: "./img/products/celik cekme boru.webp",
      desc:"ŞAFT",
      brand: "HASÇELİKLER",
      material: "KROM",
      count: "1",
    },
    {
      img: "./img/products/Kısa kovan.webp",
      desc:"KOVAN BORUSU",
      brand: "HASÇELİKLER",
      material: "ÇELİK",
      count: "1",
    },
    {
      img: "./img/products/Saft yatagı.webp",
      desc:"YATAK",
      brand: "HASÇELİKLER",
      material: "BRONZ",
      count: "1",
    },
    {
      img: "./img/products/saft korugu.webp",
      desc:"ŞAFT KÖRÜĞÜ",
      brand: "HASÇELİKLER",
      material: "KAUÇUK",
      count: "1",
    },
    {
      img: "./img/products/Sıkmalı kaplin.webp",
      desc:"SIKMALI KAPLİN",
      brand: "HASÇELİKLER",
      material: "ÇELİK",
      count: "1",
    },
    {
      img: "./img/products/Esnek kaplin.webp",
      desc:"ESNEK KAPLİN",
      brand: "HASÇELİKLER",
      material: "TPU",
      count: "1",
    },
   
  ];

  return (
    <section className="hero-section overflow-hidden swipe-section panel flex flex-col justify-end items-center  h-screen w-screen  absolute top-0  z-10">
    <div className="bg-[#212C42] h-screen w-screen">

    </div>

    <div className="hero-table-container w-screen  bg-slate-50 absolute  flex flex-col pt-4 items-center">
    <div className="w-full  xl:w-1/3 h-auto  bg-gray-200 ">
      <ComponentTableImg tableData={tableData} />
      </div>
      </div>
    </section>
  );
};
