import * as THREE from "three";
import { Hero } from "./Hero";
import Pervane from "./Pervane";
import Bg from "./Bg";
import { Canvas } from "@react-three/fiber";
import Experience from "./Experience";
import { Footer } from "flowbite-react";
import React from "react";
import "../Home.css";

const GetOverlayProductDesc = React.lazy(() =>
  import("./GetOverlayProductDesc")
);

const Home = () => {
  const cameraSettings = {
    position: [0, 0, 2],
    fov: 35,
    near: 0.1,
    far: 1000,
  };
  return (
    <div className="Home ">
      <div className="home-hds-logo absolute h-screen w-screen flex flex-col justify-between items-center ">
        <div className="image-container h-[50vh] pb-32 pt-16">
          <img src="./hds_logo_color.svg" alt="hds logo" className=" h-full  w-full"></img>
        </div>
        <div className="home-text-container h-[50vh] w-screen flex justify-start items-end ">
          <h1 className=" text-[14vw] md:text-[12vw] lg:text-[8vw] font-black text-neutral-200  leading-[14vw] md:leading-[12vw] lg:leading-[8vw] text-left ">
            ENTEGRE<br/>SEVK<br/>SİSTEMLERİ
          </h1>
        </div>
      </div>
      <Hero />
      <Pervane />
      <GetOverlayProductDesc
        productName="saftDesc"
        classN="saft-section"
        title="Şaft"
        to="/Paslanmaz-Celik-Cubuk"
      />
      <GetOverlayProductDesc
        productName="sacteknekovan"
        classN="kovan-section"
        title="Sac Tekne Kovan Sistemi"
        to="/Sac-Tekne-Kovan-Sistemi"
      />
      <GetOverlayProductDesc
        productName="korukDesc"
        classN="koruk-section"
        title="Şaft Körüğü"
        to="/Saft-Korugu"
      />
      <GetOverlayProductDesc
        productName="skaplinDesc"
        classN="skaplin-section"
        title="Sıkmalı Kaplin"
        to="/Sikmali-Kaplin"
      />
      <GetOverlayProductDesc
        productName="ekaplinDesc"
        classN="ekaplin-section"
        title="Esnek Kaplin"
        to="/Esnek-Kaplin"
      />
      <Bg />

      <Canvas
        className="canvas-container z-30"
        gl={{
          alpha: true,
          antialias: true,
          toneMapping: THREE.ACESFilmicToneMapping,
          outputColorSpace: THREE.SRGBColorSpace,
        }}
        camera={cameraSettings}
        shadows
        dpr={[1, 2]}
      >
        <Experience />
      </Canvas>
     
    </div>
  );
};

export default Home;
