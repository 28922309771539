import React from "react";

import ProductDescCard from "../Reuse/ProductDescCard";
import ComponentTableImg from "../Reuse/ComponentTableImg";
import SpecTable from "../Reuse/SpecTable";

const Ppervane = () => {

  const specData = [

      {
        fields: ["20","20","15","50","6","3"],    
      },
      {
        fields: ["25","25","19","60","6","3"],    
      },
      {
        fields: ["30","30","22","70","6","3"],    
      },
      {
        fields: ["35","35","26","80","8","4"],    
      },
      {
        fields: ["40","40","30","90","8","4"],    
      },
      {
        fields: ["45","45","34","100","10","5"],    
      },
      {
        fields: ["50","50","38","120","10","5"],    
      },
      {
        fields: ["55","55","42","130","12","6"],    
      },
      {
        fields: ["60","60","46","140","12","6"],    
      },
      {
        fields: ["65","65","50","150","14","7"],    
      },
      {
        fields: ["70","70","54","160","16","8"],    
      },
      {
        fields: ["75","74,5","57,5","170","18","9"],    
      },
      {
        fields: ["80","79,5","61,5","180","18","9"],    
      },
      {
        fields: ["85","84,5","65,5","190","18","9"],    
      },
      {
        fields: ["90","89,5","69,5","200","18","9"],    
      },
      {
        fields: ["95","94,5","73,5","210","20","10"],    
      },
      {
        fields: ["100","99","77","220","20","10"],    
      },
      {
        fields: ["110","109","85","240","22","10"],    
      },
      {
        fields: ["120","119","93","260","25","12"],    
      },
      {
        fields: ["130","129","101","280","30","15"],    
      },
      {
        fields: ["140","139","109","300","30","15"],    
      },
      {
        fields: ["150","149","117","320","32","15"],    
      },
      {
        fields: ["160","159","125","340","35","16"],    
      },
    ]  

  return (
    <div className="overflow-hidden h-auto w-screen absolute z-50 top-20  bg-gray-200 flex flex-wrap items-center justify-around">
      <div className="text-[4vmax] font-black tracking-wider text-[#212C42]   flex items-end w-screen p-8">
        Pervane
      </div>

      <div className="flex  items-center justify-center lg:max-w-[50vh]  px-4">
        <img
          src="/img/products/Pervane.webp"
          alt="Pervane"
          className=""
        />
      </div>


      <div className="pX-4 lg:max-w-[50vh]  mix-blend-luminosity">
        <img
          src="/img/teknik/pervane teknik.webp"
          alt="pervane"
          className=""
        />
      </div>

      <div className="desc w-full  flex flex-row items-center justify-center lg:items-start lg:justify-start">
        <SpecTable  cols={["ŞAFT", "D", "D1", "L", "b", "h"]} specData={specData} />
      </div>
    </div>
    
  );
};

export default Ppervane;
