import React from "react";

import ProductDescCard from "../Reuse/ProductDescCard";
import ComponentTableImg from "../Reuse/ComponentTableImg";
import SpecTable from "../Reuse/SpecTable";

const Pahsapkovsis = () => {
  const tableData = [
    {
      no: 1,
      desc: "KOVAN BORUSU",
      brand: "HASÇELİKLER",
      material: "ÇELİK ÇEKME",
      count: "1",
    },
    {
      no: 2,
      desc: "ARKA GLEN",
      brand: "HASÇELİKLER",
      material: "BRONZ",
      count: "1",
    },
    {
      no: 3,
      desc: "İÇ GLEN",
      brand: "HASÇELİKLER",
      material: "BRONZ",
      count: "1",
    },
    {
      no: 4,
      desc: "ŞAFT YATAĞI",
      brand: "HASÇELİKLER",
      material: "BRONZ+KAUÇUK",
      count: "1",
    },
    {
      no: 5,
      desc: "ŞAFT YATAĞI",
      brand: "HASÇELİKLER",
      material: "BRONZ+KAUÇUK",
      count: "1",
    },
    {
      no: 6,
      desc: " ŞAFT KEÇESİ",
      brand: "HASÇELİKLER",
      material: "PSLNMZ ÇLK+KAUÇUK",
      count: "1",
    },

  ];

  const descData = [
    {
      title: "Sızdırmazlık",
      desc: "Ahşap teknelerde kovan borusu teknenin gövdesine krom civatalarla bağlanır.",
    },
    {
      title: "Koruyucu",
      desc: "Kovan borusunun uzun ömürlü olması için et kalınlığı 4mm ve üzeri çekme paslanmaz çelik borular tercih edilir.",
    },
    {
      title: "Sızdırmazlık",
      desc: "Teknenin gövdesine kolay montaj yapılması için bronz yataklar geniş flanşlı olarak dökülüp hazırlanır.",
    },
    {
      title: "Sızdırmazlık",
      desc: "Şaft yatakları aşındığında bronz yataklar kovan borusundan sökülerek değiştirilebilir.",
    },
  ];

  
  return (
    <div className="overflow-hidden h-auto w-screen absolute z-50 top-20  bg-gray-200 flex flex-wrap items-center justify-around">
      <div className="text-[4vmax] font-black tracking-wider text-[#212C42]   flex items-end w-screen p-8">
        AHŞAP TEKNE KOVAN SİSTEMİ
      </div>

      <div className="flex  items-center justify-center lg:max-w-[50vh]  p-16">
        <img
          src="/img/products/ahsap kovan.webp"
          alt="ahşap tekne kovan sistemi"
          className=""
        />
      </div>
      <div className="lg:max-w-[50vh] lg:pr-8 ">
        <ComponentTableImg tableData={tableData} />
      </div>

      <div className="desc px-4 my-8 lg:w-[50vw] lg:max-w-[50vw]  flex flex-row items-center justify-center lg:items-start lg:justify-start">
        <ProductDescCard descData={descData} />
      </div>

    </div>
    
  );
};

export default Pahsapkovsis;
