import React, { useState } from "react";
import Googlemapcomp from "./Reuse/Googlemapcomp";
import axios from "axios";
import Requestform from "./Requestform";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    var xhr = new XMLHttpRequest();

    xhr.addEventListener("load", () => {

console.log(xhr.responseText)

    });

    xhr.open(
      'GET',
      'https://www.hdsmarin.com/sendemail/index.php?sendto=' +
        formData.email +
        '&name=' +
        formData.name +
        "&message=" +
        formData.message
    );
    xhr.send();

    e.preventDefault();
    /*     try {
        const response = await axios.post('http://localhost:5000/send-email', formData);
        console.log(response.data);
        // İletişim formunun başarıyla gönderildiğine dair bir mesaj gösterebilirsiniz
      } catch (error) {
        console.error('Hata:', error);
        // Hata durumunda kullanıcıya bir hata mesajı gösterebilirsiniz
      }
      */
  };

  return (
<>



<Requestform/>



    <div className="flex flex-col justify-center items-center gap-12">
      <div className="h-[25vh] w-full flex justify-center items-center mt-20">
        <img
          src="/img/web/lighthouse.webp"
          className="h-full  w-full object-contain "
        ></img>
      </div>

      <div className="w-[100vw] flex flex-col  justify-center items-center ">
        <div className="grid max-w-screen-xl grid-cols-1 gap-8 px-8 py-16 mx-auto rounded-lg md:grid-cols-2 md:px-12 lg:px-16 xl:px-32 bg-gray-800 text-gray-100">
          <div className="flex flex-col justify-between">
            <div className="space-y-2">
              <h2 className="text-4xl font-bold leadi lg:text-5xl">İletişim</h2>
              <div className="text-gray-400">Lorem ipusm</div>
            </div>
            <img
              src="assets/svg/doodle.svg"
              alt=""
              className="p-6 h-52 md:h-64"
            />
          </div>
          <form onSubmit={handleSubmit} novalidate="" className="space-y-6">
            <div>
              <label for="name" className="text-sm">
                Ad
              </label>
              <input
                id="name"
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required="true"
                placeholder=""
                className="w-full p-3 rounded bg-gray-100"
              />
            </div>
            <div>
              <label for="email" className="text-sm">
                E-Posta
              </label>
              <input
                id="email"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required="true"
                className="w-full p-3 rounded bg-gray-100"
              />
            </div>
            <div>
              <label for="message" className="text-sm">
                Mesaj
              </label>
              <textarea
                id="message"
                rows="3"
                name="message"
                value={formData.message}
                onChange={handleChange}
                required={true}
                className="w-full p-3 rounded bg-gray-100"
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full p-3 text-sm font-bold tracki uppercase rounded bg-gray-100 text-gray-900"
            >
              Gönder
            </button>
          </form>
        </div>
        <Googlemapcomp />
      </div>
    </div>
    </>
  );
};

export default Contact;
