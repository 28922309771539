import React from "react";

import ProductDescCard from "../Reuse/ProductDescCard";
import ComponentTableImg from "../Reuse/ComponentTableImg";
import SpecTable from "../Reuse/SpecTable";
import GetProductDesc from "../GetProductDesc";

const Pkovan = () => {
  const tableData = [
    {
      no: 1,
      desc: "KOVAN BORUSU",
      brand: "HASÇELİKLER",
      material: "ÇELİK ÇEKME",
      count: "1",
    },
    {
      no: 2,
      desc: "ARKA GLEN",
      brand: "HASÇELİKLER",
      material: "BRONZ",
      count: "1",
    },
    {
      no: 3,
      desc: "İÇ GLEN",
      brand: "HASÇELİKLER",
      material: "BRONZ",
      count: "1",
    },
    {
      no: 4,
      desc: "ŞAFT YATAĞI",
      brand: "HASÇELİKLER",
      material: "BRONZ+KAUÇUK",
      count: "1",
    },
    {
      no: 5,
      desc: "ŞAFT YATAĞI",
      brand: "HASÇELİKLER",
      material: "BRONZ+KAUÇUK",
      count: "1",
    },
    {
      no: 6,
      desc: " ŞAFT KÖRÜĞÜ",
      brand: "HASÇELİKLER",
      material: "PSLNMZ ÇLK+KAUÇUK",
      count: "1",
    },

  ];



  
  return (
    <div className="overflow-hidden h-auto w-screen absolute z-50 top-20  bg-gray-200 flex flex-wrap items-center justify-around">
      <div className="text-[4vmax] font-black tracking-wider text-[#212C42]   flex items-end w-screen p-8">
        SAC TEKNE KOVAN SİSTEMİ
      </div>

      <div className="flex  items-center justify-center lg:max-w-[50vh]  p-16">
        <img
          src="/img/products/Kısa kovan.webp"
          alt="sac tekne kovan sistemi"
          className=""
        />
      </div>
      <div className="lg:max-w-[50vh] lg:pr-8 ">
        <ComponentTableImg tableData={tableData} />
      </div>

      <div className="desc px-4 my-8 lg:w-[50vw] lg:max-w-[50vw]  flex flex-row items-center justify-center lg:items-start lg:justify-start">
      <GetProductDesc productName="sacteknekovanDesc"/> 
           </div>

    </div>
    
  );
};

export default Pkovan;
